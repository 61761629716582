<template>
  <b-card class="m-3">
    <strong> Booking Date :</strong>
    {{ moment(data.booking_date).format("LL") }} <br />
    <strong> Price :</strong> {{ data.price }} USD <br />
    <strong>Travellers :</strong> {{ data.travellers.length }}
    <br />
    <strong> Full Payment :</strong> {{ data.full_payment ? "Yes" : "No" }}
    <br />
    <strong> Payment Status :</strong> {{ data.booking_payment_status }}
    <br />

    <strong> Program :</strong> {{ data.program_id && data.program_id.name }} (
    {{ data.tribe_id.name }})
    <br />
    <strong>Created :</strong>
    {{ moment(data.createdAt).format("LLL") }} <br />
    <div class="mt-3" style="display: flex; flex-wrap: wrap; justify-content: space-between"
      v-if="data.booking_approval_status == 0">
      <b-button @click="approveBooking(data._id)" size="sm">{{
      approving ? "Approving..." : "Approve"
    }}</b-button>
      <b-button @click="rejectBooking(data._id)" size="sm">{{
      rejecting ? "Rejecting..." : "Reject"
        }}</b-button>
    </div>

  </b-card>
</template>

<script>
import { booking } from "@/config/api/booking";

export default {
  props: ["data", "userType", "status"],
  data() {
    return {
      approving: false,
      rejecting: false,
    };
  },
  methods: {
    openModal(id) {
      this.$bvModal.show(`traveller${id}`);
    },
    approveBooking(id) {
      this.$bvModal
        .msgBoxConfirm("Are you sure, do you want to approve the booking?", {
          title: "Please Confirm",
          size: "sm",
          buttonSize: "sm",
          okVariant: "danger",
          okTitle: "YES",
          cancelTitle: "NO",
          footerClass: "p-2",
          hideHeaderClose: false,
          centered: true,
        })
        .then((value) => {
          if (value) {
            const api = booking.approveBooking;
            api.data = {
              type: this.userType,
              bookingId: id,
            };
            this.approving = true;

            this.generateAPI(api)
              .then(() => {
                this.$bvToast.toast("Booking Approved", {
                  title: "Booking Approvel",
                  variant: "success",
                  solid: true,
                });
                this.$emit("loadData");
              })
              .catch((err) => {
                this.$bvToast.toast(
                  `Booking approvel failed with the error ${err.response.data}`,
                  {
                    title: "Booking Approvel",
                    variant: "danger",
                    solid: true,
                  }
                );
              })
              .finally(() => {
                this.approving = false;
              });
          }
        });
    },
    rejectBooking(id) {
      this.$bvModal
        .msgBoxConfirm("Are you sure, do you want to reject the booking?", {
          title: "Please Confirm",
          size: "sm",
          buttonSize: "sm",
          okVariant: "danger",
          okTitle: "YES",
          cancelTitle: "NO",
          footerClass: "p-2",
          hideHeaderClose: false,
          centered: true,
        })
        .then((value) => {
          if (value) {
            const api = booking.rejectBooking;
            api.data = {
              type: this.userType,
              bookingId: id,
            };
            this.rejecting = true;
            this.generateAPI(api)
              .then(() => {
                this.$bvToast.toast("Booking Rejected successfully", {
                  title: "Booking Rejection",
                  variant: "success",
                  solid: true,
                });
                this.$emit("loadData");
              })
              .catch((err) => {
                this.$bvToast.toast(
                  `Booking rejection failed with the error ${err.response.data}`,
                  {
                    title: "Booking Rejection",
                    variant: "danger",
                    solid: true,
                  }
                );
              })
              .finally(() => {
                this.rejecting = false;
              });
          }
        });
    },
  },

};
</script>

<style>
.view-traveller {
  border: none;
  background-color: gray;
  color: #fff;
  border-radius: 4px;
  margin-left: 4px;
  font-size: 12px;
}
</style>
